import React from "react"

import { navigate } from "gatsby"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { AuthContext } from "../store"

const RegisterPage = () => {
  const { isAuthenticated, registerUser } = React.useContext(AuthContext)

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      alert("Passwords do not match")
      return
    }

    registerUser({ email, password })
  }

  return (
    <AuthWrapper title="Register" isRegister>
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="email"
          id="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isBlock
          required
        />
        <Input
          type="password"
          id="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isBlock
          required
        />
        <Input
          type="password"
          id="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Register
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default RegisterPage
